import React from "react";
import { Button } from "../../../../common";

import styles from "./ActionMenu.module.scss";
import strings from "../../../../../../i18n/strings";
import { useHistory } from "react-router";
import { Path } from "../../../../../consts/router";
import { ReactComponent as PlusIcon } from "../../../../../../images/icons/plus.svg";
import { ReactComponent as PeopleIcon } from "../../../../../../images/icons/people.svg";
import { ReactComponent as EyeIcon } from "../../../../../../images/menuIcons/eye.svg";
import { ReactComponent as InfoIcon } from "../../../../../../images/icons/info.svg";
import { ReactComponent as PlayIcon } from "../../../../../../images/icons/play.svg";
import ConfirmStartNewFundraiserDialog from "../Dialogs/ConfirmStartNewFundraiserDialog";
import DialogsStore, { Dialogs } from "../../../../../store/DialogsStore";
import AppStore from "../../../../../store/AppStore";

interface IProps {
  donateValue: number | string;
  studentCount: number | string;
  teamsCount: number;
  isStripeConnected: boolean;
  isClosed?: boolean;
  shareLink?: string;
  currency: string;
  onStripeConnectClick: () => void;
  openInviteDialog: () => void;
}

const ActionMenuComponent = ({
  donateValue,
  studentCount,
  teamsCount,
  isClosed,
  onStripeConnectClick,
  isStripeConnected,
  shareLink,
  currency,
  openInviteDialog,
}: IProps) => {
  const history = useHistory();

  const onClickInviteStudents = () => {
    if (teamsCount > 0) {
      openInviteDialog();
    } else {
      history.push(Path.teams);
    }
  };

  const onStartNewFundraiserConfirmed = () => {
    DialogsStore.onClose();
    history.push(Path.signUpFundraiserInfo);
    AppStore.setLayout();
  };

  const onStartNewFundraiser = () => 
    DialogsStore.setActiveDialog(Dialogs.FUNDRAISER_START_CONFIRM);

  return (
    <div className={styles.widgetWrapper}>
      {isClosed ? (
        <div className={styles.closedEventButton}>
          <InfoIcon fill={styles.lightYellow} />

          <div className={styles.description}>
            {strings.header.closed_event}
          </div>
          
          <Button
            className={styles.startNewBtn}
            buttonColor={styles.whiteBG}
            borderRadius="20px"
            startIcon={<PlayIcon />}
            onClick={onStartNewFundraiser}
          >
            {strings.header.start_new}
          </Button>
        </div>
      ) : (
        <div className={styles.openEventWrapper}>
          {isStripeConnected ? (
            <a
              href={`${shareLink}/donate`}
              target="_blank"
              rel="noreferrer"
              className={`${styles.button} ${styles.donate} ${
                donateValue < 100 ? styles.small : ""
              }`}
            >
              <div>
                <div className={styles.startIcon}>{currency}</div>
                <div className={styles.value}>{donateValue}</div>
                <div className={styles.label}>
                  {strings.header.donate_now} <PlusIcon />
                </div>
              </div>
            </a>
          ) : (
            <a
              href={Path.main}
              onClick={(e) => {
                e.preventDefault();
                onStripeConnectClick();
                return false;
              }}
              className={`${styles.button}  ${styles.small}`}
            >
              <div>
                <div className={styles.startIcon}>{currency || "$"}</div>
                <div className={styles.value}>{donateValue}</div>
                <div className={styles.label}>
                  {strings.overview.empty_widget.connect_stripe} <PlusIcon />
                </div>
              </div>
            </a>
          )}

          <div
            className={`${styles.button} ${styles.students}`}
            onClick={onClickInviteStudents}
          >
            <div>
              <PeopleIcon className={styles.startIcon} />
              <div className={styles.value}>{studentCount}</div>
              <div className={styles.label}>
                {strings.header.invite_students} <PlusIcon />
              </div>
            </div>
          </div>

          <a
            href={`${shareLink}`}
            target="_blank"
            rel="noreferrer"
            className={`${styles.button} ${styles.donationPage}`}
          >
            <div>
              <EyeIcon fill="white" className={styles.startIcon} />
              <div className={styles.label}>
                {strings.header.view_donation_page}
              </div>
            </div>
          </a>
        </div>
      )}

      <ConfirmStartNewFundraiserDialog onStart={onStartNewFundraiserConfirmed} />
    </div>
  );
};

export default ActionMenuComponent;
